import React, { useState } from "react";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

const Pagination = ({ totalRecords, recordsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const maxPageButtons = 5;

  const handleClick = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const showEllipsisStart = currentPage > Math.floor(maxPageButtons / 2) + 1;
    const showEllipsisEnd =
      currentPage < totalPages - Math.floor(maxPageButtons / 2);

    if (totalPages <= maxPageButtons) {
      // If total pages are less than or equal to max buttons, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li
            key={i}
            className={` ${currentPage === i ? "pageActive" : ""}`}
            onClick={() => handleClick(i)}
          >
            <a href="void:javascript(0)">{i}</a>
          </li>
        );
      }
    } else {
      // Show ellipses and page numbers with proper boundaries
      if (showEllipsisStart) {
        pageNumbers.push(
          <li
            key={1}
            className={` ${currentPage === 1 ? "pageActive" : ""}`}
            onClick={() => handleClick(1)}
          >
            <a href="void:javascript(0)">1</a>
          </li>
        );
        pageNumbers.push(
          <li key="ellipsis-start" className="disabled">
            <a href="void:javascript(0)">...</a>
          </li>
        );
      }

      const startPage = showEllipsisStart
        ? Math.max(2, currentPage - Math.floor(maxPageButtons / 2))
        : 2;
      const endPage = showEllipsisEnd
        ? Math.min(totalPages - 1, startPage + maxPageButtons - 3)
        : totalPages - 1;

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <li
            key={i}
            className={`${currentPage === i ? "pageActive" : ""}`}
            onClick={() => handleClick(i)}
          >
            <a href="void:javascript(0)">{i}</a>
          </li>
        );
      }

      if (showEllipsisEnd) {
        pageNumbers.push(
          <li key="ellipsis-end" className="disabled">
            <a href="void:javascript(0)">...</a>
          </li>
        );
        pageNumbers.push(
          <li
            key={totalPages}
            className={`${currentPage === totalPages ? "pageActive" : ""}`}
            onClick={() => handleClick(totalPages)}
          >
            <a href="void:javascript(0)">{totalPages}</a>
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <>
      <div className="pagination-container">
        <ul className="pagination">
          <li
            className={`${currentPage === 1 ? "disabled" : ""}`}
            onClick={() => handleClick(currentPage - 1)}
          >
            <a href="void:javascript(0)" className="page-link">
              <MdKeyboardDoubleArrowLeft />
            </a>
          </li>
          {renderPageNumbers()}
          <li
            className={`${currentPage === totalPages ? "disabled" : ""}`}
            onClick={() => handleClick(currentPage + 1)}
          >
            <a href="void:javascript(0)" className="page-link">
              <MdKeyboardDoubleArrowRight />
            </a>
          </li>
        </ul>

        <div className="RecordMsg">
          Page {currentPage} of {totalPages} ({totalRecords} Items)
        </div>
      </div>
    </>
  );
};

export default Pagination;
