import React, { useEffect, useState } from "react";
import dollor from "../../assets/img/dollor.svg";
import wallet_balance from "../../assets/img/wallet-balance.svg";
import pattern from "../../assets/img/pattern.svg";
import axios from "axios";

const WithdrawalRequest = () => {
  const [data, setData] = useState([]);
  const storedData = JSON.parse(localStorage.getItem("token"));
  const TMDID = storedData[0].TMDID;
  const API = process.env.REACT_APP_API_URL;

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    axios
      .post(`${API}/api/getWalletdata`, { TMDID })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  var BONUS = 0;

  if (data.length > 0) {
    BONUS = data[0].BONUSWALLET;
  }
  return (
    <>
      <section className="main-page-wrp">
        <h4 className="text-white"> New Withdrawal</h4>
        <div className="card">
          <div className="card-body" id="form-box">
            <div className="row justify-content-center ">
              <div className="col-md-12 col-lg-10 col-xl-5 ">
                {/* Balance Card */}
                <div className="card transertowallet mb-3">
                  <div className="card-body">
                    <div className="icons-price">
                      <img src={dollor} alt="dollor" />
                      <h3 className="text-white">{formatNumber(BONUS)}</h3>
                    </div>
                    <img
                      src={wallet_balance}
                      alt="wallet-balance"
                      className="doller-sp"
                    />
                    <a href="void:javascript(0)" className="text-white text-xs">
                      Wallet Balance
                    </a>
                    <img
                      src={pattern}
                      alt="pattern"
                      className="pattern-shape"
                    />
                  </div>
                </div>
                {/* form */}
                <div className id="form-box">
                  <div className="form-group">
                    <label>
                      Amount:{" "}
                      <span className="required txtamount text-red-500 ">
                        *
                      </span>
                    </label>
                    <input
                      type="number"
                      id="txtamount"
                      className="cssOnlyNumericDecimal form-control"
                      maxLength={8}
                    />
                  </div>
                  <div id="payablesection">
                    <div className="form-group hidden">
                      <label>
                        Admin Charge : <span id="admincharger" />
                      </label>
                    </div>
                    <div className="form-group hidden">
                      <label>
                        Payable Amount : <span id="payableAmount" />
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Wallet Address (BEP20 Network):
                      <span className="required text-red-500"> *</span>
                    </label>
                    <input
                      type="text"
                      id="txtAddress"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group hidden" id="otpdiv">
                    <label>Email OTP: </label>
                    <input type="text" className="form-control" id="txtotp" />
                  </div>
                  {/* buttons */}
                  <div className="form-group row justify-content-center">
                    <div className="col-md-6">
                      <button
                        className="mr-1 join-now w-100"
                        type="submit"
                        id="btnsubmit"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="mr-1 join-now w-100"
                        type="submit"
                        id="btncancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WithdrawalRequest;
