import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
// Dashboard
import Dashboard from "../Pages/Dashboard";
// G-USD Wallet
import NewDeposit from "../Pages/G-USD Wallet/NewDeposit";
import GSWNewDeposit from "../Pages/G-USD Wallet/GSWNewDeposit";
import DepositHistory from "../Pages/G-USD Wallet/DepositHistory";
import GSWDepositHistory from "../Pages/G-USD Wallet/GSWDepositHistory";
import WalletStatement from "../Pages/G-USD Wallet/WalletStatement";
import TransferToMember from "../Pages/G-USD Wallet/TransferToMember";
// T-USD Wallet
import USNewDeposit from "../Pages/T-USD Wallet/USNewDeposit";
import USDepositHistory from "../Pages/T-USD Wallet/USDepositHistory";
import USWalletStatement from "../Pages/T-USD Wallet/USWalletStatement";
import USTransferToMember from "../Pages/T-USD Wallet/USTransferToMember";
// Direct Team
import DirectReferral from "../Pages/Direct Team/DirectReferral";
import TeamList from "../Pages/Direct Team/TeamList";
// Packages Summary
import PurchasedHistory from "../Pages/Packages Summary/PurchasedHistory";
// Rewards Report
import DirectReferralRewards from "../Pages/Rewards Report/DirectReferralRewards";
import DailyPOR from "../Pages/Rewards Report/DailyPOR";
import DailyPORClickHistory from "../Pages/Rewards Report/DailyPORClickHistory";
import RewardsWalletStatement from "../Pages/Rewards Report/RewardsWalletStatement";
// Withdrawals
import WithdrawalRequest from "../Pages/Withdrawals/WithdrawalRequest";
import WithdrawalHistory from "../Pages/Withdrawals/WithdrawalHistory";
// Login, Logout & SignUp
import SignUp from "../components/Auth/SignUp";
import Login from "../components/Auth/Login";
import Logout from "../components/Auth/Logout";
// RouteWrapper
import RouteWrapper from "../components/RouteWrapper";

// Wrap components with layout
const Loginwithoutlayout = RouteWrapper(Login, false);
// Dashboard
const DashboardWithLayout = RouteWrapper(Dashboard, true);
// G-USD Wallet
const NewDepositWithLayout = RouteWrapper(NewDeposit, true);
const GSWNewDepositLayout = RouteWrapper(GSWNewDeposit, true);
const DepositHistoryLayout = RouteWrapper(DepositHistory, true);
const GSWDepositHistoryLayout = RouteWrapper(GSWDepositHistory, true);
const WalletStatementLayout = RouteWrapper(WalletStatement, true);
const TransferToMemberLayout = RouteWrapper(TransferToMember, true);
// T-USD Wallet
const USNewDepositLayout = RouteWrapper(USNewDeposit, true);
const USDepositHistoryLayout = RouteWrapper(USDepositHistory, true);
const USWalletStatementLayout = RouteWrapper(USWalletStatement, true);
const USTransferToMemberLayout = RouteWrapper(USTransferToMember, true);
// Packages Summary
const PurchasedHistoryLayout = RouteWrapper(PurchasedHistory, true);
// Direct Team
const DirectReferralLayout = RouteWrapper(DirectReferral, true);
const TeamListLayout = RouteWrapper(TeamList, true);
// Rewards Report
const DirectReferralRewardsLayout = RouteWrapper(DirectReferralRewards, true);
const DailyPORLayout = RouteWrapper(DailyPOR, true);
const DailyPORClickHistoryLayout = RouteWrapper(DailyPORClickHistory, true);
const RewardsWalletStatementLayout = RouteWrapper(RewardsWalletStatement, true);
// Withdrawals
const WithdrawalRequestLayout = RouteWrapper(WithdrawalRequest, true);
const WithdrawalHistoryLayout = RouteWrapper(WithdrawalHistory, true);

// Used for the dynamic tab title updates
const DynamicTitle = () => {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    const titleMap = {
      "/": "Login",
      "/register": "Sign Up",
      "/logout": "Logout",
      "/dashboard": "GSV || Dashboard",
      "/ewalletdeposit": "G-USD New Deposit",
      "/gswwalletdeposit": "GSW New Deposit",
      "/ewalletdeposithistory": "G-USD Deposit History",
      "/gswwalletdeposithistory": "GSW Deposit History",
      "/e-wallet-statement": "G-USD Wallet Statement",
      "/e-wallet-transfer": "G-USD Transfer to Member",
      "/usdwalletdeposit": "T-USD New Deposit",
      "/usdwalletdeposithistory": "T-USD Deposit History",
      "/usd-wallet-statement": "T-USD Wallet Statement",
      "/usd-wallet-transfer": "T-USD Transfer to Member",
      "/packagehistory": "Packages Summary",
      "/direct-team-list": "Direct Team List",
      "/total-team-list": "Total Team List",
      "/direct-bonus": "Direct Bonus",
      "/porrewards": "Daily POR",
      "/porhistory": "DailyPORClickHistory",
      "/rewards-wallet-statement": "Rewards Wallet Statement",
      "/new-Withdrawal": "New Withdrawal",
      "/withdrawal-history": "Withdrawal History",
    };
    document.title = titleMap[path] || "Default Title";
  }, [location]);
  return null;
};

const Router = () => {
  return (
    <BrowserRouter>
      <DynamicTitle />
      <Routes>
        <Route path="/" element={<Loginwithoutlayout />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/logout" element={<Logout />} />
        {/* Dashboard */}
        <Route path="/dashboard" element={<DashboardWithLayout />} />
        {/* G-USD Wallet */}
        <Route path="/ewalletdeposit" element={<NewDepositWithLayout />} />
        <Route path="/gswwalletdeposit" element={<GSWNewDepositLayout />} />
        <Route
          path="/ewalletdeposithistory"
          element={<DepositHistoryLayout />}
        />
        <Route
          path="/gswwalletdeposithistory"
          element={<GSWDepositHistoryLayout />}
        />
        <Route path="/e-wallet-statement" element={<WalletStatementLayout />} />
        <Route path="/e-wallet-transfer" element={<TransferToMemberLayout />} />
        {/* T-USD Wallet */}
        <Route path="/usdwalletdeposit" element={<USNewDepositLayout />} />
        <Route
          path="/usdwalletdeposithistory"
          element={<USDepositHistoryLayout />}
        />
        <Route
          path="/usd-wallet-statement"
          element={<USWalletStatementLayout />}
        />
        <Route
          path="/usd-wallet-transfer"
          element={<USTransferToMemberLayout />}
        />
        {/* Packages Summary */}
        <Route path="/packagehistory" element={<PurchasedHistoryLayout />} />
        {/* Direct Team */}
        <Route path="/direct-team-list" element={<DirectReferralLayout />} />
        <Route path="/total-team-list" element={<TeamListLayout />} />
        {/* Rewards Report */}
        <Route path="/direct-bonus" element={<DirectReferralRewardsLayout />} />
        <Route path="/porrewards" element={<DailyPORLayout />} />
        <Route path="/porhistory" element={<DailyPORClickHistoryLayout />} />
        <Route
          path="/rewards-wallet-statement"
          element={<RewardsWalletStatementLayout />}
        />
        {/* Withdrawals */}
        <Route path="/new-Withdrawal" element={<WithdrawalRequestLayout />} />
        <Route
          path="/withdrawal-history"
          element={<WithdrawalHistoryLayout />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
