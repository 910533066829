import React from "react";
import Layout from "./Layout";

const RouteWrapper = (Component, layoutRequired) => {
  return (props) => {
    if (layoutRequired) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }
    return <Component {...props} />;
  };
};

export default RouteWrapper;
