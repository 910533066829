import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useLoader } from "../../components/LoaderContext";
import axios from "axios";
import DatePicker from "react-datepicker";
import Pagination from "../Pagination";

const USWalletStatement = () => {
  // Manage Page Loader
  const { showLoader, hideLoader } = useLoader();

  const [statementData, setData] = useState([]);
  const [FROMDATE, setFROMDATE] = useState(null);
  const [TODATE, setTODATE] = useState(null);
  const [TYPE, setTYPE] = useState(""); // Set initial value to an empty string
  const [totalRecords, setTotal] = useState(0);
  const [Recordsperpage, setRecordsperpage] = useState(0);
  const [STARTROWINDEX, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const storedData = JSON.parse(localStorage.getItem("token"));
  const TMDID = storedData[0].TMDID;
  const API = process.env.REACT_APP_API_URL;

  const fetchData = (page = 1) => {
    setLoading(true);
    showLoader();

    axios
      .post(`${API}/api/getustwalletsummary`, {
        TMDID,
        STARTROWINDEX: page,
        FROMDATE,
        TODATE,
        TYPE, // Include TYPE in the API request
      })
      .then((response) => {
        setData(response.data.records);
        setTotal(response.data.total);
        setRecordsperpage(response.data.recordsperpage);
        setLoading(false);
        hideLoader();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        hideLoader();
      });
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    fetchData(STARTROWINDEX);
  }, [STARTROWINDEX, FROMDATE, TODATE, TYPE]); // Add TYPE to dependency array

  const handlePageChange = (page) => {
    if (page !== STARTROWINDEX) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <section className="main-page-wrp">
        <h4 className="text-white"> T-USD Statement</h4>
        <div className="card">
          <div className="card-body">
            {/* filter button */}
            <div className="text-end mb-2">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                data-bs-toggle="offcanvas"
                data-bs-target="#filter"
              >
                <FaFilter className=" ms-1 inline-flex items-center" /> Filter
              </button>
            </div>
            {/* filter form */}
            <div className="offcanvas offcanvas-end" id="filter">
              <div className="offcanvas-header">
                <h4 className="offcanvas-title">Filter</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                />
              </div>
              <div className="offcanvas-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setCurrentPage(1); // Reset to first page on filter submit
                    fetchData(1); // Fetch data after form submit
                  }}
                >
                  <div className="form-group">
                    <DatePicker
                      selected={FROMDATE}
                      onChange={(date) => setFROMDATE(date)}
                      className="form-control"
                      placeholderText="From Date"
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                  <div className="form-group">
                    <DatePicker
                      selected={TODATE}
                      onChange={(date) => setTODATE(date)}
                      className="form-control"
                      placeholderText="To Date"
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                  <div className="form-group">
                    <select
                      value={TYPE}
                      onChange={(e) => setTYPE(e.target.value)} // Ensure state is updated
                      className="form-control"
                      id="Type"
                      name="Type"
                    >
                      <option value="">-Type-</option>
                      <option value="Cr">Cr</option>
                      <option value="Dr">Dr</option>
                    </select>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      setFROMDATE(null);
                      setTODATE(null);
                      setTYPE(""); // Reset TYPE to default value
                      setCurrentPage(1); // Reset to first page on clear
                      fetchData(1); // Fetch with cleared filters
                    }}
                  >
                    Clear All
                  </button>
                </form>
              </div>
            </div>
            {/* Main Content */}
            <div className="table-responsive">
              <table className="table table-hover table-striped new-table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Previous Balance</th>
                    <th>Current Balance</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {statementData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.RowNumber}</td>
                      <td>{item.ProcessDate}</td>
                      <td>{item.CrDrType}</td>
                      <td>
                        {item.Cr_Amount > 0 ? (
                          <span className="creditledger">
                            +{formatNumber(item.Cr_Amount)}
                          </span>
                        ) : (
                          <span className="debittledger">
                            -{formatNumber(item.Dr_Amount)}
                          </span>
                        )}
                      </td>
                      <td>{formatNumber(item.Old_Balance)}</td>
                      <td>{formatNumber(item.Balance)}</td>
                      <td>{item.Remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="divPaging">
              <Pagination
                totalRecords={totalRecords}
                recordsPerPage={Recordsperpage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default USWalletStatement;
