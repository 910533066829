import React, { useEffect, useState } from "react";
import { useLoader } from "../../components/LoaderContext";
import axios from "axios";

const DailyPORClickHistory = () => {
  // Manage Page Loader
  const { showLoader, hideLoader } = useLoader();

  const [statementData, setData] = useState([]);
  const [STARTROWINDEX, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const storedData = JSON.parse(localStorage.getItem("token"));
  const TMDID = storedData[0].TMDID;
  const API = process.env.REACT_APP_API_URL;

  const fetchData = (page = 1) => {
    setLoading(true);
    showLoader();

    axios
      .post(`${API}/api/getDailyPORClickHistory`, {
        TMDID,
      })
      .then((response) => {
        setData(response.data.records);
        setLoading(false);
        hideLoader();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        hideLoader();
      });
  };
  useEffect(() => {
    fetchData();
  }, []); // Add TYPE to dependency array

  // Date-Time Formatting Function
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    return new Intl.DateTimeFormat("en-IN", options).format(
      new Date(dateTimeString)
    );
  };
  return (
    <>
      <section className="main-page-wrp">
        <h4 className="text-white"> Daily Proof of Rewards Click History</h4>
        <div className="card">
          <div className="card-body">
            {/* Main Content */}
            <div className="table-responsive">
              <table className="table table-hover table-striped new-table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Date</th>
                    <th>Is Cliamed</th>
                    <th>Cliam Now</th>
                  </tr>
                </thead>
                <tbody>
                  {statementData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.RowNumber}</td>
                      <td>{formatDateTime(item.DATE)}</td>
                      <td>
                        {item.CLICKSTATUS === 1 ? (
                          <span className="clickstatusyes">Yes</span>
                        ) : (
                          <span className="clickstatusno">No</span>
                        )}
                      </td>
                      <td>
                        {item.ISCLICKABLE === 1 ? (
                          <span className="isclickableclaim">Claim Now</span>
                        ) : (
                          <span className="isclickableNaN">N/A</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DailyPORClickHistory;
