import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useLoader } from "../../components/LoaderContext";
import axios from "axios";
import Pagination from "../Pagination";
import DatePicker from "react-datepicker";

const GSWDepositHistory = () => {
  // Manage Page Loader
  const { showLoader, hideLoader } = useLoader();

  const [statementData, setData] = useState([]);
  const [FROMDATE, setFROMDATE] = useState(null);
  const [TODATE, setTODATE] = useState(null);
  const [TYPE, setTYPE] = useState(""); // Set initial value to an empty string
  const [totalRecords, setTotal] = useState(0);
  const [Recordsperpage, setRecordsperpage] = useState(0);
  const [STARTROWINDEX, setCurrentPage] = useState(1);
  const [SEARCHVALUE, setSEARCHVALUE] = useState(null);
  const [SEARCHFIELD, setSEARCHFIELD] = useState(null);
  const [loading, setLoading] = useState(false);
  const storedData = JSON.parse(localStorage.getItem("token"));
  const TMDID = storedData[0].TMDID;
  const API = process.env.REACT_APP_API_URL;

  const fetchData = (page = 1) => {
    setLoading(true);
    showLoader();

    axios
      .post(`${API}/api/getgswdeposithistory`, {
        TMDID,
        STARTROWINDEX: page,
        FROMDATE,
        TODATE,
        TYPE,
        SEARCHFIELD,
        SEARCHVALUE,
      })
      .then((response) => {
        setData(response.data.records);
        setTotal(response.data.total);
        setRecordsperpage(response.data.recordsperpage);
        setLoading(false);
        hideLoader();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        hideLoader();
      });
  };

  useEffect(() => {
    fetchData(STARTROWINDEX);
  }, [STARTROWINDEX, FROMDATE, TODATE, TYPE, SEARCHFIELD, SEARCHVALUE]); // Add TYPE to dependency array

  const handlePageChange = (page) => {
    if (page !== STARTROWINDEX) {
      setCurrentPage(page);
    }
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  // Calculate total quantity and total amount
  const totalQuantity = statementData.reduce(
    (total, item) => total + (item.COINRATE || 0),
    0
  );
  const totalAmount = statementData.reduce(
    (total, item) => total + (item.AMOUNT || 0),
    0
  );

  // Date-Time Formatting Function
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    return new Intl.DateTimeFormat("en-IN", options).format(
      new Date(dateTimeString)
    );
  };

  return (
    <>
      <section className="main-page-wrp">
        <h4 className="text-white"> G-USD GSW Deposit History</h4>
        <div className="card">
          <div className="card-body">
            {/* filter button */}
            <div className="text-end mb-2">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                data-bs-toggle="offcanvas"
                data-bs-target="#filter"
              >
                <FaFilter className=" ms-1 inline-flex items-center" /> Filter
              </button>
            </div>
            {/* filter form */}
            <div className="offcanvas offcanvas-end" id="filter">
              <div className="offcanvas-header">
                <h4 className="offcanvas-title">Filter</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                />
              </div>
              <div className="offcanvas-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setCurrentPage(1); // Reset to first page on filter submit
                    fetchData(1); // Fetch data after form submit
                  }}
                >
                  <div className="form-group">
                    <DatePicker
                      selected={FROMDATE}
                      onChange={(date) => setFROMDATE(date)}
                      className="form-control"
                      placeholderText="From Date"
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                  <div className="form-group">
                    <DatePicker
                      selected={TODATE}
                      onChange={(date) => setTODATE(date)}
                      className="form-control"
                      placeholderText="To Date"
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      setFROMDATE(null);
                      setTODATE(null);
                      setTYPE(""); // Reset TYPE to default value
                      setCurrentPage(1); // Reset to first page on clear
                      fetchData(1); // Fetch with cleared filters
                    }}
                  >
                    Clear All
                  </button>
                </form>
              </div>
            </div>
            {/* Main Content */}
            <div className="table-responsive">
              <table className="table table-hover table-striped new-table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Address</th>
                    <th>Date</th>
                    <th>Network</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {statementData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.ROWNUMBER}</td>
                      <td>{item.BTCADDRESS}</td>
                      <td>
                        {formatDateTime(item.FROMGENERATEDDATEANDTIMEDATE)}
                      </td>
                      <td>{item.COINCODE}</td>
                      <td>{formatNumber(item.AMOUNT)}</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://bscscan.com/tx/0x` + item.TXTID}
                          style={{ color: "#198754" }}
                        >
                          confirm
                        </a>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>Total:</td>
                    <td>{formatNumber(totalAmount)}</td>
                    <td colSpan={1} />
                  </tr>
                </tbody>
              </table>
              <div className="divPaging">
                <Pagination
                  totalRecords={totalRecords}
                  recordsPerPage={Recordsperpage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GSWDepositHistory;
