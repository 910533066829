import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import heading_bg from "../assets/heading_bg.mp4";
import bell from "../assets/img/bell.svg";
import avatar from "../assets/img/avatar.jpg";
import { MdMenu, MdMenuOpen } from "react-icons/md";
import logo from "../assets/img/logo.png";
import logo_thum from "../assets/img/logo-white.png";
import dashboard from "../assets/img/dashboard.svg";
import wallet from "../assets/img/wallet.svg";
import usdwallet from "../assets/img/usdwallet.svg";
import Purchase_Packages from "../assets/img/Purchase-Packages.svg";
import My_Team from "../assets/img/My-Team.svg";
import Income_Report from "../assets/img/Income-Report.svg";
import Withdrawals from "../assets/img/Withdrawals.svg";
import logout from "../assets/img/logout.svg";

const Header = () => {
  const storedData = JSON.parse(localStorage.getItem("token"));
  const Username = storedData[0].USERNAME;
  const LoginID = storedData[0].LOGINID;

  // for header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // for sidebar toggler
  const [SidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!SidebarOpen);
    document.body.classList.toggle("collapses", !SidebarOpen);
  };

  return (
    <>
      {/* Header */}
      <div className="strip-bg">
        <video autoPlay loop muted>
          <source src={heading_bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <header className={isSticky ? "sticky" : ""}>
        <div className="page-txt">
          <div className="toggle-btn" onClick={toggleSidebar}>
            {SidebarOpen ? (
              <MdMenu color="white" size={25} />
            ) : (
              <MdMenuOpen color="white" size={25} />
            )}
          </div>
        </div>
        <aside>
          <span className="bell-notfication">
            <img src={bell} alt="bell" />
          </span>
          <div className="user-hd-detail">
            <div className="contract-add-user">
              <h6 className="text-white">{Username}</h6>
              <div className="text-white">{LoginID}</div>
            </div>
            <div className="dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <img src={avatar} alt="avatar" />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/direct-team-list">
                    Direct Members
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/packagehistory">
                    Purchase History
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/logout">
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      </header>
      {/* Sidebar */}
      <div className={`side-menu ${SidebarOpen ? "open" : "closed"}`}>
        <div className="logo-header">
          <img src={logo} alt="logo" className="logo-white" />
          <img
            src={logo_thum}
            alt="logo_thum"
            className="logo-white-thum hidden"
          />
        </div>
        <div className="pagelink-wrp">
          <ul className="mn-list">
            <li className="mn-th no-inter-menu active">
              <a href="dashboard">
                <span className="icon-menu">
                  <img src={dashboard} alt="dashboard" />
                </span>{" "}
                <span className="text-mn">Dashboard</span>
              </a>
            </li>
            {/*-------G-USD Wallet-------*/}
            <li className="mn-th">
              <div className="view-main-menu">
                <span className="icon-menu">
                  <img src={wallet} alt="wallet" />{" "}
                </span>{" "}
                <span className="text-mn">G-USD Wallet</span>
              </div>
              <ul className="inner-mn-th">
                <li className="inner-li">
                  <a href="/ewalletdeposit">
                    <span className="text-mn">New Deposit</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/gswwalletdeposit">
                    <span className="text-mn">GSW New Deposit</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/ewalletdeposithistory">
                    <span className="text-mn">Deposit History</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/gswwalletdeposithistory">
                    <span className="text-mn">GSW Deposit History</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/e-wallet-statement">
                    <span className="text-mn">Wallet Statement</span>
                  </a>
                </li>
                <li className="inner-li last-cborder">
                  <a href="/e-wallet-transfer">
                    <span className="text-mn">Transfer To Member</span>
                  </a>
                </li>
              </ul>
            </li>
            {/*-------T-USD Wallet-------*/}
            <li className="mn-th">
              <div className="view-main-menu">
                <span className="icon-menu">
                  <img src={usdwallet} alt="usdwallet" />{" "}
                </span>{" "}
                <span className="text-mn">T-USD Wallet</span>
              </div>
              <ul className="inner-mn-th">
                <li className="inner-li">
                  <a href="/usdwalletdeposit">
                    <span className="text-mn">New Deposit</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/usdwalletdeposithistory">
                    <span className="text-mn">Deposit History</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/usd-wallet-statement">
                    <span className="text-mn">Wallet Statement</span>
                  </a>
                </li>
                <li className="inner-li last-cborder">
                  <a href="/usd-wallet-transfer">
                    <span className="text-mn">Transfer To Member</span>
                  </a>
                </li>
              </ul>
            </li>
            {/*-------Packages Summary-------*/}
            <li className="mn-th">
              <div className="view-main-menu">
                <span className="icon-menu">
                  <img src={Purchase_Packages} alt="purchase_packages" />{" "}
                </span>{" "}
                <span className="text-mn">Packages Summary</span>
              </div>
              <ul className="inner-mn-th">
                <li className="inner-li">
                  <a href="/packagehistory">
                    <span className="text-mn">Purchased History</span>
                  </a>
                </li>
              </ul>
            </li>
            {/*-------Direct Team-------*/}
            <li className="mn-th">
              <span className="icon-menu">
                <img src={My_Team} alt="my_team" />
              </span>{" "}
              <span className="text-mn">Direct Team</span>
              <ul className="inner-mn-th">
                <li className="inner-li">
                  <a href="/direct-team-list">
                    <span className="text-mn">Direct Referral</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/total-team-list">
                    <span className="text-mn">Team List</span>
                  </a>
                </li>
              </ul>
            </li>
            {/*-------Rewards Report-------*/}
            <li className="mn-th">
              <div className="view-main-menu">
                <span className="icon-menu">
                  <img src={Income_Report} alt="income_report" />
                </span>{" "}
                <span className="text-mn">Rewards Report</span>
              </div>
              <ul className="inner-mn-th">
                <li className="inner-li">
                  <a href="/direct-bonus">
                    <span className="text-mn">Direct Referral Rewards</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/porrewards">
                    <span className="text-mn">Daily POR</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/porhistory">
                    <span className="text-mn">Daily POR Click History</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/rewards-wallet-statement">
                    <span className="text-mn">Rewards Wallet Statement</span>
                  </a>
                </li>
              </ul>
            </li>
            {/*-------Withdrawals-------*/}
            <li className="mn-th">
              <div className="view-main-menu">
                <span className="icon-menu">
                  <img src={Withdrawals} alt="withdrawals" />
                </span>
                <span className="text-mn">Withdrawals</span>
              </div>
              <ul className="inner-mn-th">
                <li className="inner-li">
                  <a href="/new-Withdrawal">
                    <span className="text-mn">Withdrawal Request</span>
                  </a>
                </li>
                <li className="inner-li">
                  <a href="/withdrawal-history">
                    <span className="text-mn">Withdrawal History</span>
                  </a>
                </li>
              </ul>
            </li>
            {/*-------Logout-------*/}
            <li className="mn-th">
              <a href="/logout">
                <span className="icon-menu">
                  <img src={logout} alt="logout" />
                </span>{" "}
                <span className="text-mn">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
