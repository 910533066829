import React, { useEffect, useState } from "react";
import dollor from "../../assets/img/dollor.svg";
import wallet_balance from "../../assets/img/wallet-balance.svg";
import pattern from "../../assets/img/pattern.svg";
import { FaComment, FaMoneyBillWave, FaPassport, FaUser } from "react-icons/fa";
import axios from "axios";

const USTransferToMember = () => {
  const [data, setData] = useState([]);
  const storedData = JSON.parse(localStorage.getItem("token"));
  const TMDID = storedData[0].TMDID;
  const API = process.env.REACT_APP_API_URL;

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    axios
      .post(`${API}/api/getWalletdata`, { TMDID })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  var TUSD = 0;

  if (data.length > 0) {
    TUSD = data[0].USDWALLET;
  }
  return (
    <>
      <section className="main-page-wrp">
        <h4 className="text-white"> T-USD Transfer </h4>
        <div className="card">
          <div className="card-body">
            <div className="row justify-content-center ">
              <div className="col-md-12 col-lg-10 col-xl-5 ">
                {/* Balance Card */}
                <div className="card transertowallet mb-4">
                  <div className="card-body">
                    <div className="icons-price">
                      <img src={dollor} alt="dollor" />
                      <h3 className="text-white">{formatNumber(TUSD)}</h3>
                    </div>
                    <img
                      src={wallet_balance}
                      alt="wallet_balance"
                      className="doller-sp"
                    />
                    <p className="text-white text-xs">Balance</p>
                    <img
                      src={pattern}
                      alt="pattern"
                      className="pattern-shape"
                    />
                  </div>
                </div>
                {/* form */}
                <div className="form-group">
                  <label htmlFor>Amount</label>
                  <div className="input-wrp">
                    <input
                      name="txtamount"
                      type="text"
                      maxLength={20}
                      id="txtamount"
                      className="Disallowspace form-control"
                    />
                    <FaMoneyBillWave className="fa" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor>Username</label>
                  <div className="input-wrp">
                    <input
                      name="txtusername"
                      type="text"
                      maxLength={20}
                      id="txtusername"
                      className="Disallowspace form-control"
                    />
                    <FaUser className="fa" />
                  </div>
                </div>
                <div className="form-group d-none otpdiv">
                  <label htmlFor>OTP</label>
                  <div className="input-wrp">
                    <input
                      name="txtotp"
                      type="text"
                      maxLength={20}
                      id="txtotp"
                      className="Disallowspace form-control"
                    />
                    <FaPassport className="fa" />
                    <div>
                      <small>An OTP has been sent in your mail address</small>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor>Remarks</label>
                  <div className="input-wrp">
                    <input
                      name="txtRemarks"
                      type="text"
                      maxLength={100}
                      id="txtRemarks"
                      className="form-control"
                    />
                    <FaComment className="fa" />
                  </div>
                </div>
                {/* buttons */}
                <div className="form-group row">
                  <div className="col-md-6">
                    <button className="mr-1 join-now w-100" id="btnsubmit">
                      Submit
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button className="join-now outline-jn w-100">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default USTransferToMember;
