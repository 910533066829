import React, { useState } from "react";
import dotted_1 from "../../assets/img/dotted-1.png";
import dotted_2 from "../../assets/img/dotted-2.png";
import logo_white from "../../assets/img/logo-white.png";
import { FaEye, FaEyeSlash, FaUser } from "react-icons/fa";
// import shape_bg from "../../assets/img/shape_bg - Copy.svg";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "./Loader";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const API = process.env.REACT_APP_API_URL;

  const handleLogin = async (event) => {
    setLoading(true); // Show the loader
    event.preventDefault();
    try {
      const response = await axios.post(`${API}/api/login`, {
        username,
        password,
      });
      localStorage.setItem("token", JSON.stringify(response.data));
      if (response.data) {
        window.location.href = "/dashboard";
      } else {
        toast.warning("Username or password is incorrect!");
        setLoading(false); // Hide the loader
      }
    } catch (error) {
      toast.error("There is some technical glitch! We are working on it");
      setLoading(false); // Hide the loader
    }
  };

  return (
    <>
      {loading && <Loader />} {/* Show the loader when loading is true */}
      <div className="login-pg login-page">
        <form onSubmit={handleLogin} className="form1">
          <div className="row row-new">
            <div className="col-md-7 left_login_wrp">
              <div className="welcome-bx">
                <img
                  src="/img/texture.svg"
                  alt="texture"
                  className="texture_svg"
                />
                <div className="logo_box_wrp">
                  <div className="logo_box">
                    <img src={logo_white} alt="logo" />
                  </div>
                  <h1>Galaxy Skyverse</h1>
                  <p>Bringing Metaverse in Space to your Home!</p>
                </div>
                <img
                  src="/img/left_bottom.svg"
                  alt="left bottom"
                  className="left_bottom_svg "
                />
                <img
                  src="/img/leaf_bottom.svg"
                  alt="leaf bottom"
                  className="leaf_bottom_svg"
                />
              </div>
            </div>
            <div className="col-md-5 right_login_wrp">
              <img
                src="/img/right_leaf.svg"
                alt="right leaf"
                className="right_leaf_svg"
              />
              <img
                src="/img/right_top.svg"
                alt="right top"
                className="right_top_svg"
              />
              <div className="welcome-bx-content">
                <h2 className="sing_heading">Sign In</h2>
                <p className="please_enter">
                  Please enter your username and password to sign in to your
                  account
                </p>
                <div className="form-group">
                  <label htmlFor="txtUserName">User Name</label>
                  <div className="input-wrp">
                    <input
                      name="txtUserName"
                      type="text"
                      maxLength={20}
                      id="txtUserName"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                    <FaUser className="fa fa-user" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="txtPassword">Password</label>
                  <div className="input-wrp">
                    <input
                      name="txtPassword"
                      type={showPassword ? "text" : "password"}
                      id="txtPassword"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    {showPassword ? (
                      <FaEyeSlash
                        className="show-password fa fa-eye-slash"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <FaEye
                        className="show-password fa fa-eye"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
                <div className="captcha-container">
                  <input
                    type="text"
                    id="captcha"
                    style={{ background: "#dfdcd8" }}
                    placeholder="Captcha"
                    required
                  />
                  <a id="btncaptachrefresh">
                    <img
                      src="/img/refresh-icon.png"
                      alt="Refresh"
                      style={{ width: 32, height: 32 }}
                    />
                  </a>
                  <input
                    type="text"
                    placeholder="Enter Captcha"
                    id="usercaptcha"
                    maxLength={6}
                    required
                  />
                </div>
                <a
                  href="https://privatesale.galaxyskyverse.com/forgotpassword"
                  className="forgot_text"
                >
                  Forgot Password?
                </a>
                <button type="submit" className="login-btn w-100 loginnow">
                  {" "}
                  Login{" "}
                </button>
                <div className="signup-txt">
                  Don't have an account?{" "}
                  <a href="https://privatesale.galaxyskyverse.com/register">
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
