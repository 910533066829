import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { LoaderProvider } from "./components/LoaderContext"; // Adjust the import path as necessary

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <React.StrictMode>
      <LoaderProvider>
        <App />
        <ToastContainer />
      </LoaderProvider>
    </React.StrictMode>
  </>
);
reportWebVitals();
