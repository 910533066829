import React, { useEffect, useState } from "react";
import dollor from "../../assets/img/dollor.svg";
import wallet_balance from "../../assets/img/wallet-balance.svg";
import pattern from "../../assets/img/pattern.svg";
import { FaClipboard } from "react-icons/fa6";
import axios from "axios";

const USNewDeposit = () => {
  const [data, setData] = useState([]);
  const storedData = JSON.parse(localStorage.getItem("token"));
  const TMDID = storedData[0].TMDID;
  const API = process.env.REACT_APP_API_URL;

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    axios
      .post(`${API}/api/getWalletdata`, { TMDID })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  var TUSD = 0;

  if (data.length > 0) {
    TUSD = data[0].USDWALLET;
  }
  return (
    <>
      <section className="main-page-wrp">
        <h4 className="text-white">T-USD Deposit</h4>
        <div className="card">
          <div className="card-body">
            <div id="USDT-topup">
              <div className="row justify-content-center ">
                <div className="col-md-12 col-lg-10 col-xl-5 ">
                  {/* Current Balance Card */}
                  <div className="card transertowallet mb-4">
                    <div className="card-body">
                      <div className="icons-price">
                        <img src={dollor} alt="dollor" />
                        <h3 className="text-white">{formatNumber(TUSD)}</h3>
                      </div>
                      <img
                        src={wallet_balance}
                        alt="wallet_balance"
                        className="doller-sp"
                      />
                      <p className="text-white">Current Balance</p>
                      <img
                        src={pattern}
                        alt="pattern"
                        className="pattern-shape"
                      />
                    </div>
                  </div>
                  {/* payment section */}
                  <div className="qr-d-wrp">
                    {/* paymn qr */}
                    <picture className="qr-dv">
                      <div className="scanner" />
                      <img
                        src="https://api.qrserver.com/v1/create-qr-code/?data=0x54CD51f4DA6Cf098615da84b04d466D3f2FbF724"
                        alt="qr"
                        className=" ml-36"
                        id="WalletAddressQR"
                      />
                    </picture>
                  </div>
                  <div className="paymn-tab-wrp">
                    <div className="paymn-tab">
                      <a href className="paymoad-tab active-pay" id="BEP20">
                        Binance-Peg BSC-USD (BSC-USD)
                        <span>
                          {" "}
                          <i className="fa fa-check"> </i>{" "}
                        </span>
                        <input
                          type="hidden"
                          defaultValue="BSCUSD"
                          id="tokenName"
                        />
                        <input type="hidden" defaultValue={2} id="type" />
                      </a>
                    </div>
                    <div className="qr-right-br">
                      <div className="referral-label">
                        <span id="walletaddress">
                          0x54CD51f4DA6Cf098615da84b04d466D3f2FbF724
                        </span>
                        <a
                          href="void:javascript(0)"
                          id="btnCopy"
                          className="btnCopy-txt"
                        >
                          <FaClipboard aria-hidden="true" />
                        </a>
                      </div>
                      <div className="fTransaction-Hash pt-1">
                        <label htmlFor="txtAmount" unselectable="on">
                          Transaction Hash{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="TranID"
                          className="login-input input-disable form-control mb-2"
                        />
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <button
                            className="mr-1 join-now w-100"
                            id="btnsubmit"
                          >
                            Submit
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button className="join-now outline-jn w-100">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <input type="hidden" id="NetwrokName" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default USNewDeposit;
