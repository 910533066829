import React from "react";
import Header from "../components/Header";

const Layout = ({ children }) => {
  const storedData = JSON.parse(localStorage.getItem("token"));
  if (storedData == null) {
    window.location.href = "/";
    return <div>Login Required</div>;
  }
  return (
    <>
      <Header />
      <main>{children}</main>
    </>
  );
};

export default Layout;
