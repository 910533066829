import React from 'react'


const Loader = () => {
  return (
    <>
       <div className="loader">
      <div className="spinner"></div>
    </div>
    </>
  )
}

export default Loader
