import React, { useRef, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import Router from "./routes/Router";
import LoadingBar from "react-top-loading-bar";

const App = () => {
  // for PregressBar/LoadingBar on top of the header
  const ref = useRef(null);
  useEffect(() => {
    ref.current.continuousStart();
    setTimeout(() => {
      ref.current.complete();
    }, 500);
  }, []);

  return (
    <>
      <LoadingBar color="#e99d26" ref={ref} />
      <Router />
    </>
  );
};

export default App;
