import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GiWallet } from "react-icons/gi";
import elite from "../assets/img/elite.png";
import master from "../assets/img/master.png";
import prodigy from "../assets/img/prodigy.png";
import gsv from "../assets/img/gsv.png";

import {
  FaAddressBook,
  FaCheck,
  FaRegCopy,
  FaRegPaperPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { FaClipboard, FaDollarSign } from "react-icons/fa6";
import axios from "axios";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const storedData = JSON.parse(localStorage.getItem("token"));
  const TMDID = storedData[0].TMDID;
  const USERNAME = storedData[0].USERNAME;
  const API = process.env.REACT_APP_API_URL;

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    axios
      .post(`${API}/api/getWalletdata`, { TMDID })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  var GUSD = 0;
  var TUSD = 0;
  var BONUS = 0;
  var POR = 0;
  var TOKENPURCHASED = 0;
  var GSVADDRESS = "";
  var TOTALTOKEN = 0;
  var BALANCETOKEN = 0;
  var SOLD = 0;
  // var PORSSTATUS = 0;
  var DIRECTBUSINESS = 0;
  var RATE = 0;
  var CurrentValue = 0;

  if (data.length > 0) {
    GUSD = data[0].EWALLET;
    TUSD = data[0].USDWALLET;
    BONUS = data[0].BONUSWALLET;
    POR = data[0].ROIINCOME;
    TOTALTOKEN = data[0].TOTALTOKEN;
    BALANCETOKEN = data[0].BALANCETOKEN;
    SOLD = data[0].SOLD;
    GSVADDRESS = data[0].GSVADDRESS;
    // PORSSTATUS = data[0].PORSSTATUS;
    TOKENPURCHASED = data[0].TOKENPURCHASED;
    DIRECTBUSINESS = data[0].DIRECTBUSINESS;
    RATE = data[0].RATE;
    CurrentValue = TOKENPURCHASED * RATE;
  }

  // alert for "Claim Your Today's POR"
  const handleClaimClick = (e) => {
    const userConfirmed = window.confirm("Are you sure want to claim?");
    if (!userConfirmed) {
      e.preventDefault();
    }
  };

  // for copy shareLink used in Referral card with toastify
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    const referralLink = document.getElementById("ReferralLinkShare");
    if (referralLink) {
      referralLink.select();
      navigator.clipboard.writeText(referralLink.value);
      try {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
        toast.success("Link copied to clipboard!", {
          autoClose: 1000,
        });
      } catch (error) {
        toast.error("Failed to copy link");
      }
    }
  };

  // for gauge control used in Token Rate Assumption card
  const [rate, setRate] = useState(0.9);
  const [spinnerStyle, setSpinnerStyle] = useState({});
  const [pointerStyle, setPointerStyle] = useState({});

  useEffect(() => {
    updateGauge(rate);
  }, [rate]);

  const updateGauge = (newRate) => {
    const minRate = 0.1;
    const maxRate = 5.0;
    const percentage = ((newRate - minRate) / (maxRate - minRate)) * 100;

    // Assuming the pointer rotates with the gauge spinner
    setSpinnerStyle({
      transform: `rotate(${(percentage * 180) / 100}deg)`,
    });
    // If the pointer needs to be positioned in a different manner, adjust here
    setPointerStyle({
      transform: `rotate(${(percentage * -360) / 100}deg)`,
    });
  };

  //  For progress bar used in rank details & sale summary
  var TotalRequiredBusiness = 100000;
  var scaledirect = (DIRECTBUSINESS * 100) / TotalRequiredBusiness;
  var scaleSale = (SOLD * 100) / TOTALTOKEN;

  // for Referral QR
  const qrCodeURL = `https://api.qrserver.com/v1/create-qr-code/?data=http://privatesale.galaxyskyverse.com//register?Referrelcode=${USERNAME}`;

  return (
    <>
      <section className="main-page-wrp">
        {/* Dashboard Heading Name & Claim POR */}
        <div className="row mb-3">
          <div className="col-md-10">
            <h4 className="text-white mb-0">Dashboard</h4>
          </div>
          <div className="col-md-2">
            <a
              href="/"
              className="claim-roi float-right"
              id="claimpor"
              onClick={handleClaimClick}
            >
              Claim Your Today's POR
            </a>
          </div>
        </div>
        {/* Wallets */}
        <div className="row">
          {/* G-USD Wallet */}
          <div className="col-md-6 col-lg-3">
            <div className="card crd color_1">
              <a href="e-wallet-statement">
                <div className="card-body">
                  <p> G-USD</p>
                  <h4>{formatNumber(GUSD)}</h4>
                </div>
              </a>
              <span className="pic_cd">
                <GiWallet size={26} color="#ffffff" />
              </span>
            </div>
          </div>
          {/* T-USD Wallet */}
          <div className="col-md-6 col-lg-3">
            <div className="card crd color_2">
              <a href="usd-wallet-statement">
                <div className="card-body">
                  <p>T-USD</p>
                  <h4>{formatNumber(TUSD)}</h4>
                </div>
              </a>
              <span className="pic_cd">
                <GiWallet size={26} color="#ffffff" />
              </span>
            </div>
          </div>
          {/* Rewards Wallet */}
          <div className="col-md-6 col-lg-3">
            <div className="card crd color_3">
              <a href="rewards-wallet-statement">
                <div className="card-body">
                  <p>Rewards Wallet</p>
                  <h4>{formatNumber(BONUS)}</h4>
                </div>
              </a>
              <span className="pic_cd">
                <GiWallet size={26} color="#ffffff" />
              </span>
            </div>
          </div>
          {/* Total POR */}
          <div className="col-md-6 col-lg-3">
            <div className="card crd color_4">
              <a href="porrewards">
                <div className="card-body">
                  <p>Total POR</p>
                  <h4>{POR}</h4>
                </div>
              </a>
              <span className="pic_cd">
                <GiWallet size={26} color="#ffffff" />
              </span>
            </div>
          </div>
        </div>
        {/* Marquee-Container */}
        <div className="marquee-container">
          <div
            className="marquee-content"
            onMouseOver={() =>
              (document.getElementById("myMarquee").style.animationPlayState =
                "paused")
            }
            onMouseOut={() =>
              (document.getElementById("myMarquee").style.animationPlayState =
                "running")
            }
            id="myMarquee"
          >
            Missed your daily claim? No issue! We have launched a feature to
            click on the last 30 days' claims. Simply
            <Link to="/porhistory">Click Here</Link> to claim your missed date.
          </div>
        </div>
        {/* Crads */}
        <div className="row">
          <div className="col-lg-12 col-xl-7">
            <div className="row mb-2">
              {/* Nebula Starter */}
              <div className="col-md-4">
                <div className="card package_dg package_1">
                  <div className="card-body">
                    <div className="package_content">
                      <div className="package_text">
                        <p>GSV</p>
                        <h4>Nebula Starter</h4>
                      </div>
                      <div className="specail-dg">
                        <div className="specail-br">
                          <span>0.03% Daily POR</span>
                        </div>
                        <div className="price-dtl">
                          <span className="total-vl">$50</span>
                          <div className="total-months">
                            <sup className>$80</sup>
                            <p>for 12 months</p>
                          </div>
                        </div>
                        <button href className="join-now" id="Package4">
                          Join Nebula Starter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Galactic Entry */}
              <div className="col-md-4">
                <div className="card package_dg package_1">
                  <div className="card-body">
                    <div className="package_content">
                      <div className="package_text">
                        <p>GSV</p>
                        <h4>Galactic Entry </h4>
                      </div>
                      <div className="specail-dg">
                        <div className="specail-br">
                          <span>0.03% Daily POR</span>
                        </div>
                        <div className="price-dtl">
                          <span className="total-vl">$100</span>
                          <div className="total-months">
                            <sup className>$150</sup>
                            <p>for 12 months</p>
                          </div>
                        </div>
                        <button href className="join-now" id="Package5">
                          Join Galactic Entry
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Starlight Initiate */}
              <div className="col-md-4">
                <div className="card package_dg package_1">
                  <div className="card-body">
                    <div className="package_content">
                      <div className="package_text">
                        <p>GSV</p>
                        <h4>Starlight Initiate</h4>
                      </div>
                      <div className="specail-dg">
                        <div className="specail-br">
                          <span>0.03% Daily POR</span>
                        </div>
                        <div className="price-dtl">
                          <span className="total-vl">$300</span>
                          <div className="total-months">
                            <sup className>$450</sup>
                            <p>for 12 months</p>
                          </div>
                        </div>
                        <button href className="join-now" id="Package6">
                          Join Starlight Initiate
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Referral */}
          <div className="col-lg-12 col-xl-5">
            <div className="card Referral-bx mb-2">
              <div className="card-body">
                <div className="card-name">Referral</div>
                {/* QR */}
                <div className="row">
                  <div className="col-md-4">
                    <div className="qr-bx">
                      <picture classname="qr-dv">
                        <div classname="scanner ">
                          <img src={qrCodeURL} alt="qr" id="WalletAddressQR" />
                        </div>
                      </picture>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p className="text-sm">
                      My Sponsor:
                      <label className="form-check-label" />
                    </p>
                    <div className="shareLink">
                      <div className="permalink">
                        <input
                          className="text-sm textLink"
                          id="ReferralLinkShare"
                          type="text"
                          name="shortlink"
                          defaultValue="http://privatesale.galaxyskyverse.com//register?Referrelcode=galaxyskyverse"
                          readOnly
                        />
                        <span
                          className="copyLink"
                          id="copy"
                          tooltip="Copy to clipboard"
                          onClick={handleCopy}
                        >
                          {copied ? (
                            <FaCheck color="green" />
                          ) : (
                            <FaRegCopy color="red" />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <button className="btn btn-success whatsapp d-flex gap-2 w-100 justify-center p-2">
                          Share on <FaWhatsapp />
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button className="btn btn-warning telegram d-flex gap-2 w-100 justify-center p-2">
                          Share on <FaRegPaperPlane />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-7">
            <div className="row mb-2">
              {/* Nebula Questn */}
              <div className="col-md-4">
                <div className="card package_dg package_1">
                  <div className="card-body">
                    <div className="package_content">
                      <div className="package_text">
                        <p>GSV</p>
                        <h4>Nebula Questn</h4>
                      </div>
                      <div className="specail-dg">
                        <div className="specail-br">
                          <span>0.05% Daily POR</span>
                        </div>
                        <div className="price-dtl">
                          <span className="total-vl">$600</span>
                          <div className="total-months">
                            <sup className>$800</sup>
                            <p>for 12 months</p>
                          </div>
                        </div>
                        <button href className="join-now" id="Package1">
                          Join Nebula Questn
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Galactic Explorer */}
              <div className="col-md-4">
                <div className="card package_dg package_2">
                  <div className="card-body">
                    <div className="package_content">
                      <div className="package_text">
                        <p>GSV</p>
                        <h4>Galactic Explorer </h4>
                      </div>
                      <div className="specail-dg">
                        <div className="specail-br">
                          <span>0.10% Daily POR</span>
                        </div>
                        <div className="price-dtl">
                          <span className="total-vl">$1100</span>
                          <div className="total-months">
                            <sup className>$1500</sup>
                            <p>for 12 months</p>
                          </div>
                        </div>
                        <button href className="join-now" id="Package2">
                          Join Galactic Explorer{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Starlight Voyage */}
              <div className="col-md-4">
                <div className="card package_dg package_3">
                  <div className="card-body">
                    <div className="package_content">
                      <div className="package_text">
                        <p>GSV</p>
                        <h4>Starlight Voyage</h4>
                      </div>
                      <div className="specail-dg">
                        <div className="specail-br">
                          <span>0.20% Daily POR</span>
                        </div>
                        <div className="price-dtl">
                          <span className="total-vl">$2500</span>
                          <div className="total-months">
                            <sup className>$4500</sup>
                            <p>for 12 months</p>
                          </div>
                        </div>
                        <button href className="join-now" id="Package3">
                          Join Starlight Voyage
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Token Summary */}
          <div className="col-lg-12 col-xl-5">
            <div className="card Token-bx mb-2">
              <div className="card-body">
                <h5 className="card-name-token">Token Summary</h5>
                <span className="text-xs">
                  GSV Wallet Address:
                  <a
                    href={`https://bscscan.com/address/${GSVADDRESS}`}
                    target="blank"
                  >
                    {GSVADDRESS}
                  </a>
                </span>
                <div className="total-holding">
                  <img src={gsv} alt="" />
                  <b> GSV TOKENS : {formatNumber(TOKENPURCHASED)} </b>{" "}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="text-xs text-dark mb-1">Current Value</p>
                    <div className="rate-design active">
                      <FaDollarSign />{" "}
                      <span>{formatNumber(CurrentValue)} </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p className="text-xs text-dark mb-1">Token Rate</p>
                    <div className="rate-design">
                      <FaDollarSign /> <span className="blink_me"> {RATE}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          {/* Rank Details */}
          <div className="col-md-8">
            <div className="card Rank-Details mb-2">
              <div className="card-body">
                <h5 className="mb-2">Rank Details</h5>
                {/* Rank Details Images */}
                <div className="rank-dtl-row">
                  <div className="rank-dtl-row-content">
                    {/* ELITE */}
                    <div className="rank-bx active">
                      <div className="rank-status-pic">
                        <img src={elite} alt="elite" />
                      </div>
                      <h5>ELITE</h5>
                      <p>$25,000 Direct Business</p>
                      <span className="blink-dotted" />
                    </div>
                    {/* MASTER */}
                    <div className="rank-bx ">
                      <div className="rank-status-pic">
                        <img src={master} alt="master" />
                      </div>
                      <h5>MASTER</h5>
                      <p>$50,000 Direct Business</p>
                      <span className="blink-dotted" />
                    </div>
                    {/* PRODIGY */}
                    <div className="rank-bx ">
                      <div className="rank-status-pic">
                        <img src={prodigy} alt="prodigy" />
                      </div>
                      <h5>PRODIGY</h5>
                      <p>$1,00,000 Direct Business</p>
                      <span className="blink-dotted" />
                    </div>
                  </div>
                </div>
                {/* Progress Bar */}
                <div className="tokent-summary total-sh">
                  <div className="card-body">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: `${scaledirect}%` }}
                      >
                        {scaledirect}%
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <b className="text-xs text-dark">
                        Total Direct Business : ${formatNumber(DIRECTBUSINESS)}
                      </b>
                      <span className="text-xs text-muted">
                        Required: $1,00,000
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Token Rate & Gauge */}
          <div className="col-md-4">
            <div className="card Rank-Details mb-2">
              <div className="card-body">
                <h5 className="mb-2">Token Rate Assumption</h5>
                <div className="gauge-wrapper mb-7">
                  <div
                    className="gauge-outer mt-8"
                    id="gauge1"
                    data-min="0.10"
                    data-max="5.0"
                    data-value={rate}
                  >
                    <div className="gauge">
                      <div className="gauge-inner" />
                      <div className="gauge-spinner" style={spinnerStyle} />
                    </div>
                    <div className="pointer" style={pointerStyle} />
                    <div className="gauge-range">
                      <div className="text-xs gauge-min">$0.10</div>
                      <div className="text-xs gauge-max">$5.00</div>
                      <div className="gauge-value">${RATE.toFixed(2)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          {/* G-USD Deposit */}
          <div className="col-md-6 col-lg-6">
            <div className="card Wallet-Deposit-cd">
              <div className="card-body">
                <h5 className="mb-2">G-USD Deposit</h5>
                <div className="form-group">
                  <label
                    htmlFor="txtAmount"
                    className="text-xs"
                    unselectable="on"
                  >
                    Deposit Address <span className="text-danger">*</span>
                  </label>
                  <div className="input-with-icon both-padding">
                    <span className="spn-icon">
                      {" "}
                      <FaAddressBook />
                    </span>
                    <input
                      type="text"
                      id="ewalletaddress"
                      className="login-input input-disable form-control"
                      readOnly
                      defaultValue="0x2a54bC88242d9D9B2C57F962349169234587E5E8"
                    />
                    <span className="spn-icon right-side" id="ebtnCopy">
                      <FaClipboard aria-hidden="true" />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="txtAmount"
                    className=" text-xs"
                    unselectable="on"
                  >
                    Transaction Hash <span className="text-danger">*</span>
                  </label>
                  <div className="input-with-icon t-way-spacw">
                    <span className="spn-icon">
                      <FaDollarSign />
                    </span>
                    <input
                      type="text"
                      id="eTranID"
                      className="login-input input-disable form-control"
                    />
                  </div>
                </div>
                <div>
                  <input type="hidden" defaultValue="USDT" id="etokenName" />
                  <input type="hidden" defaultValue={1} id="etype" />
                  <button className="mr-1 join-now w-100" id="btndepositsubmit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* T-USD Deposit */}
          <div className="col-md-6 col-lg-6">
            <div className="card Wallet-Deposit-cd">
              <div className="card-body">
                <h5 className="mb-2">T-USD Deposit</h5>
                <div className="form-group">
                  <label
                    htmlFor="txtAmount"
                    className=" text-xs"
                    unselectable="on"
                  >
                    Deposit Address <span className="text-danger">*</span>
                  </label>
                  <div className="input-with-icon both-padding">
                    <span className="spn-icon">
                      {" "}
                      <FaAddressBook />
                    </span>
                    <input
                      type="text"
                      id="usdwalletaddress"
                      className="login-input input-disable form-control"
                      readOnly
                      defaultValue="0x54CD51f4DA6Cf098615da84b04d466D3f2FbF724"
                    />
                    <span className="spn-icon right-side" id="wbtnCopy">
                      <FaClipboard aria-hidden="true" />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="txtAmount"
                    className=" text-xs"
                    unselectable="on"
                  >
                    Transaction Hash <span className="text-danger">*</span>
                  </label>
                  <div className="input-with-icon t-way-spacw">
                    <span className="spn-icon">
                      {" "}
                      <FaDollarSign />
                    </span>
                    <input
                      type="text"
                      id="UsdTranID"
                      className="login-input input-disable form-control"
                    />
                  </div>
                </div>
                <div>
                  <input
                    type="hidden"
                    defaultValue="BSCUSD"
                    id="usdtokenName"
                  />
                  <input type="hidden" defaultValue={2} id="usdtype" />
                  <button
                    className="mr-1 join-now w-100"
                    id="btnusddepositsubmit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TOKEN FOR PRIVATE SALE SUMMARY */}
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card tokent-suumary total-sh">
              <div className="card-body">
                <h5>TOKEN FOR PRIVATE SALE SUMMARY</h5>
                <div className="d-flex justify-content-between">
                  <b className="text-xs text-dark">
                    Total : {formatNumber(TOTALTOKEN)}
                  </b>
                  <span className="text-xs text-muted">
                    Available For Sale: {formatNumber(BALANCETOKEN)}
                  </span>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    style={{ width: `${formatNumber(scaleSale)}%` }}
                  >
                    {formatNumber(scaleSale)}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
